import {signIn, signOut, getSession} from "next-auth/react";
import Head from 'next/head';
import Link from "next/link";
import React, { useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { useRouter } from 'next/router';

import Layout from '@/components/Layout';

const LoginPage = ({ session }) => {
  const Router = useRouter();
  const signInButtonNode = () => {
    if (session) {
      return false;
    }
    return (
      <div className="container">
        <section className="content-block">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-block">
                  <div className="form-content">
                    <h4>Log in to your Account</h4>
                    <button
                      onClick={async (e) => {
                        e.preventDefault();
                        await signIn("google", { callbackUrl: process.env.NEXTAUTH_URL })
                      }}
                      className="login-button"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        width="40" height="40"
                        viewBox="0 0 48 48">
                        <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
                      </svg>
                      <span>Login with Google</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  if (!session) {
    return (
      <Layout login>
        <Head>
          <title>Login</title>
        </Head>
        {signInButtonNode()}
      </Layout>
    )
  } else {
    useEffect(() => {
      Router.push('/')
    })
    return <div className='loader-screen'>
      <span className="loader">
        <Spinner
          as="span"
          animation="border"
          size="md"
          role="status"
          aria-hidden="true"
        />
      </span>
    </div>
  }
};

export const getServerSideProps = async ({ req }) => {
  const session = await getSession({req});
  return {
    props: {
      session,
    },
  };
};

export default LoginPage;
